/*
    Stripe related utilities methods
*/
frappe.provide("bond_assets.stripe");

$.extend(bond_assets.stripe, {

    generate_stripe_url: function(sales_partner){
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_landing.www.agreements.get_stripe_url",
                args: {
                    'sales_partner': sales_partner,
                },
                freeze: true,
                callback: (res)=>{
                    resolve(res.message);
                }
            });
        });
    }
});