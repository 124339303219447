/*
    Terms and Privacy Policy
 */

frappe.provide("bond_assets.terms_and_policy");

bond_assets.terms_and_policy.TermsAndPolicy = Class.extend({
    init: function(args){
        $.extend(this, args);
        this.make();
    },

    make: function(){
        this.get_terms_and_policy().then((data)=>{
            if(this.terms_of_use){
                this.setup(data.terms_of_use);
            }else{
                this.setup(data.privacy_and_policy);
            }
        });
    },

    setup: function(data){
        this.$wrapper = $("main");
        this.$container = $(`<section class="referral-partner-list-section">
                                <div class="container">
                                    <div class="row justify-content-center mx-auto">
                                        <div class="col-12">
                                            <h1 class="referral-partner-hero__main-title page-title text-center mb-2"></h1>
                                            <ol class="referral-partner-list-section__list mx-auto"></ol>
                                        </div>
                                    </div>
                                </div>
                            </section>`).appendTo(this.$wrapper);
        if(!bond_assets.device.is_mobile_device()){
            this.$container.addClass("pt-4");
        }

        let items = data.terms? data.terms: data.policies;
        this.set_header(data.headline);
        this.setup_items(items);
    },

    set_header: function(headline){
        this.$header = this.$wrapper.find(".referral-partner-hero__main-title");
        this.$header.html(headline);
        if(bond_assets.device.is_mobile_device()){
            this.$header.addClass("referral-partner-hero__main-title--mob");
        }else{
            this.$header.addClass("referral-partner-hero__main-title--desk");
        }
    },

    setup_items: function(items){
        this._setup_items(items, this.$container.find(".referral-partner-list-section__list"));
    },

    _setup_items: function(items, $wrapper){
        let $card, desc;

        $.each(items, (idx, item)=>{
            desc = item.description;
            if(item.title){
                desc = __("{0}.{1}", [item.title, item.description]);
            }
            $card = $(`<li class="referral-partner-list-section__list-item referral-partner-list-section-list-item js-referral-spoiler-item">
                <div class="referral-partner-list-section-list-item__inner">
                    <div class="referral-partner-list-section-list-item__spoiler-icon"></div>
                    <div class="referral-partner-list-section-list-item__content">
                        <p class="font-error-description"> ${desc}</p>
                    </div>
                </div>
            </li>`).appendTo($wrapper);
            if($card.find("a").length){
                this.handle_card_events($card);
            }
        });

        $wrapper.find('.js-referral-spoiler-item').on('click', function H() {
            $(this).find('.referral-partner-list-section-list-item__content').slideToggle(500);
            $(this).toggleClass('active');
        });

        if(bond_assets.device.is_mobile_device()){
            $wrapper.find(".js-referral-spoiler-item").click();
        }
    },

    handle_card_events: function($card){
        $card.find("a")
            .css("pointer-events", "auto")
            .attr("target", "_blank")
            .attr("onMouseOver", "this.style.textDecoration='underline'")
            .attr("onMouseOut", "this.style.textDecoration='none'")
            .on("click", (e)=>{
                let href = $(e.target).attr("href");
                window.open(href, "_blank");
                return false;
            });
        
    },

    get_terms_and_policy: function(){
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_assets.terms_and_policy.get_data",
                freeze: true,
                freeze_message: __("Loading..."),
                callback: (res)=>{
                    resolve(res.message);
                },
                error: ()=>{
                    reject();
                }
            });
        });
    }
});