/*
    Server Redirections
*/

frappe.provide("servers.redirections");

$.extend(servers.redirections, {

    see_quotes: function(){
        $(document.body).on("click", ".redirect-to-see-quotes", (e) => {
            e.preventDefault();
            let url = cstr(frappe.boot.client_portal) + "/quotes/see-quotes";
            servers.redirections.redirect(url);
            return false;
        });
    },

    terms_of_use: function(){
        $(document.body).on("click", ".redirect-to-terms-of-use", (e) => {
            e.preventDefault();
            let open_in_new_tab = $(e.currentTarget).hasClass("open-in-new-tab");
            let url = cstr(frappe.boot.landing_server) + "/terms-of-use";
            return servers.redirections.redirect(url, open_in_new_tab);
        });

    },

    privacy_and_policy: function(){
        $(document.body).on("click", ".redirect-to-privacy-policy", (e) => {
            e.preventDefault();
            let open_in_new_tab = $(e.currentTarget).hasClass("open-in-new-tab");
            let url = cstr(frappe.boot.landing_server) + "/privacy-policy";
            return servers.redirections.redirect(url, open_in_new_tab);
        });
    },

    dealership: function(open_in_new_tab){
        $(document.body).on("click", ".redirect-to-dealer-partner", (e) => {
            e.preventDefault();
            let url = cstr(frappe.boot.landing_server) + "/dealer-partner";
            return servers.redirections.redirect(url, open_in_new_tab);
        });
    },

    real_estate: function(){
        $(document.body).on("click", ".redirect-to-real-estate", (e) => {
            e.preventDefault();
            let url = cstr(frappe.boot.landing_server) + "/real-estate-professional";
            return servers.redirections.redirect(url);
        });
    },

    homepage: function(){

    },

    get_quotes_oops_page_url: function(){
        let url = cstr(frappe.boot.client_portal) + "/quotes/oops";
        if(localStorage.getItem("form_key")){
            url += "?form_key=" + cstr(localStorage.getItem("form_key"));
        }
        return url;
    },

    get_prev_page_url: function(){
        let search = new URLSearchParams(window.location.search);
        if(search.get("prev-page")){
            return search.get("prev-page");
        }
        return frappe.boot.landing_server;
    },

    get_current_page_url: function(){
        let params = {
            "prev-page": window.location.origin
        };
        return $.param(params);

    },

    redirect: function(url, open_in_new_tab=false){
        let origin = servers.redirections.get_current_page_url();

        url += "?"+origin;
        if(open_in_new_tab){
            window.open(url, "_blank");
            return false;
        }else{
            window.location.href = url;
        }
    },

    redirect_to_client_facing_form: function(res, args){
        // response (res) to validate serving area.
        let redirect_to = "";
        let searchParams = new URLSearchParams(window.location.search);

        let formname = args.line_of_insurance.toLowerCase().replace(" ", "");
        if(res.message.length >= 1){
            redirect_to = cstr(frappe.boot.client_portal) + "/" + cstr(formname) +"/?zip_code=" + cstr(args.zip_code);
        }else{
            redirect_to = cstr(frappe.boot.client_portal) + "/" + cstr(formname) + "/?is-external-rating-state-client=1&zip_code=" + cstr(args.zip_code);
        }
        if(searchParams.get("referral_code")){
            redirect_to += "&referral_code=" + searchParams.get("referral_code");
        }

        let ad_settings = ads.settings.get_ad_settings();
        if(ad_settings){
            redirect_to += "&ad_reference_name=" + ad_settings;
        }
        let session_args = ads.settings.get_google_analytical_info(false);
        if(session_args){
            redirect_to += "&" + session_args;
        }
        let utm_args = ads.settings.get_utm_params(false);
        if(utm_args){
            redirect_to += "&" + utm_args;
        }
        window.location.href = redirect_to;
    },    
 
});