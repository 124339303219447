/*
    Links for inter server commumication
 */
frappe.provide("servers.links");

$.extend(servers.links, {
    get_client_quote_display_link: function(service_type, service_name, is_manual=false){
        let url = `${frappe.boot.client_portal}/quotes/all?service_type=${service_type}&service_name=${service_name}`;
        if(is_manual){
            url += '&is_manual=1'
        }
        return url;
    }
});
