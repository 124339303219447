/*
    Development mode logger
 */

frappe.provide("bond_assets.dev");
frappe.provide("bond_assets.dev.logger");

$.extend(bond_assets.dev, {
    is_developer_mode(){
        return frappe.boot.developer_mode === 1?true:false;
    }
});

$.extend(bond_assets.dev.logger, {

    error_log: function(error_traceback){
        bond_assets.dev.logger._log(console.error, error_traceback);
    },

    log_info: function(info){
        bond_assets.dev.logger._log(console.info, info);
    },

    log_warning: function(warning){
        bond_assets.dev.logger._log(console.warn, warning);
    },

    log: function(log){
        bond_assets.dev.logger._log(console.log, log);
    },

    _log: function(log_type_caller, info){
        // private method
        try{
            if(!info || ($.isArray(info) && !info.length)){
                return false;
            }
            if(bond_assets.dev.is_developer_mode()){
                if(log_type_caller){
                    log_type_caller(info);
                }else{
                    console.log(info);
                }
            }
            return false;
            frappe.call({
                method: "bond_assets.logger.log_info",
                args:{
                    info: info
                },
                callback: ()=>{},
            });
        }catch(e){
            if(bond_assets.dev.is_developer_mode()){
                console.error(e);
            }
        }
    }
});